.link{
    padding: 10px;
    }
    
    .link.active {
        background-color: #075c8d;
        border-radius: 5px;
        height: 10px;
        color: white;
    }

    .blink_me 
    {
        animation: blinker 1s infinite;
    }
      
    @keyframes blinker {
        from { opacity: 1.0; }
        50% { opacity: 0.7; }
        to { opacity: 1.0; }
    }