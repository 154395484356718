.reveal{
    position: relative;
    transform: translateY(90px);
    opacity: 0;
    transition: all 1.5s ease;
    
}

.reveal.active{
    transform: translateY(0px);
    opacity: 1;
}
 
  